
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/style.css';
import './css/animate.css';
import './css/icomoon.css';
//import './css/normalize.css';
import './css/responsive.css';
import Favicon from 'react-favicon';
import MenuComponent from './components/menu';




import NavBar from './components/navBar'

import config from './config';


class Menu extends React.Component {



  render() {
    const backgroundColor = this.props.backgroundColor ? this.props.backgroundColor : "#F0F0F0"
    return (
      <div className="section-padding" id="menu_page" style={{background: backgroundColor}}>
          <MenuComponent
            currentShopKey = { this.props.match.params.shopId}
        />
      </div>
    );
  }





}

export default Menu

