
import React, { Component } from "react"
import { Route, Link,Redirect} from 'react-router-dom'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isIOS,
  isAndroid,
  isMacOs
} from "react-device-detect";
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/style.css';
import './css/animate.css';
import './css/icomoon.css';
//import './css/normalize.css';
import './css/responsive.css';
import Favicon from 'react-favicon';
import Loader from './animations/rings';
import {Animated} from "react-animated-css";
import config from "./config";


import NavBar from './components/navBar'




class MagicLink extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      installLink: "",
      isMobile: false
    };
  }

  componentDidMount() {
    if (isIOS) {
      this.setState({isMobile: true})
      return window.location.assign(`${config.apple}`);
    } 
    if (isAndroid) {
      this.setState({isMobile: true})
      return window.location.assign(`${config.google}`);
    } 
  }

  render() {
    return (
      <section className="section-padding mt-5" id="magic_link"  >
         {this.getContent()}
      </section>
    );
  }

  getContent(){
    if (this.state.isMobile) {
      return (
            <div className="col text-center justify-content-center" >
              <Animated animationIn="pulse"> 
              <img src={config.logo}  alt={`${config.clientName}`} className="img-fluid" />
              <Loader  size="50" stroke={"#606060"}/>
              <p>Loading</p>
              </Animated>
            </div>
      )
    } 
    return (
      <Redirect to="/" push/>
)
  }

}

export default MagicLink

