import React, { Component } from "react"

import Apple from '../images/apple-icon.png'
import Android from '../images/play-store-icon.png'
import AppleButton from '../images/apple_button.png'
import AndroidButton from '../images/google_button.png'
import ApplePay from '../images/pay_icons/apple_pay.svg'
import GooglePay from '../images/pay_icons/google_pay.svg'
import Visa from '../images/pay_icons/1.png'
import Master from '../images/pay_icons/2.png'
import Amec from '../images/pay_icons/22.png'

import config from "../config";


//  /* Old browsers */
//     /* FF3.6-15 */
// background: -webkit-linear-gradient(45deg, rgb(70, 42, 194) 0%, rgba(106, 198, 240, 1) 100%);
// /* Chrome10-25,Safari5.1-6 */
// background: -o-linear-gradient(45deg, rgba(72, 44, 191, 1) 0%, rgba(106, 198, 240, 1) 100%);
// background: linear-gradient(45deg, rgba(72, 44, 191, 1) 0%, rgba(106, 198, 240, 1) 100%);
// /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#482cbf', endColorstr='#6ac6f0', GradientType=1);



const mainColour = config.colours.mainColour
const secondColour = config.colours.secondColour
const twoToneGradient = `-webkit-linear-gradient(-45deg, ${config.colours.mainColour} 50%, ${config.colours.secondColour} 50%)`
const smoothGradient = `-webkit-linear-gradient(-45deg, ${config.colours.mainColour} 0%, ${config.colours.secondColour} 100%)`
const deskTopBackground = `url(${config.background}) no-repeat fixed center / cover`


// const gradient2 = `-o-linear-gradient(45deg, ${config.colours.mainColour} 0%, ${config.colours.secondColour} 100%)`
// const gradient3 = `linear-gradient(45deg, ${config.colours.mainBackGroundColour} 0%, ${config.colours.secondColour} 100%)`

const overlayColor = !config.colours.overlay ? 'black' : config.colours.overlay
const overlayOpacity = !config.colours.opacity ? '0.4' : config.colours.opacity
let mobileBackground = mainColour



switch (config.colours.backgroundType) {
    case '1':
        mobileBackground = mainColour
        break;
    case '2':
        mobileBackground = secondColour
        break;
    case '3':
        mobileBackground = smoothGradient
        break;
    case '4':
        mobileBackground = twoToneGradient
        break;
    case '5':
        mobileBackground = deskTopBackground
        break;
    default:
        mobileBackground = mainColour
}


class Header extends Component {

    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {

        const style = {
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            background: overlayColor,
            opacity: this.state.width < 768 ? overlayOpacity : overlayOpacity,
            /* IE6-9 fallback on horizontal gradient */
            zIndex: -2,

        }


        const headerStyles = this.state.width < 768 ? { background: mobileBackground } : { background: deskTopBackground }

        //console.log("config", config)



        const angle = config.colours.angle === "true" ? "angle" : null
        const overlay = "overlay"

        return (
            <header className={`home-area ${angle} ${overlay}`} id="home_page" style={headerStyles}>
                <div style={style}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-md-8 order-md-2 animated fadeIn d-flex flex-column justify-content-between">
                            <div className=" " >
                                <h1 className="text-center text-md-left mt-5 " ><strong>{config.headerTitle}</strong></h1>
                                <h4 className="text-center text-md-left">{config.headerSubTitle}</h4>

                                <div className="desc text-center text-md-left"  >
                                    <p>{config.headerText}</p>
                                </div>
                                <div className="row justify-content-center justify-content-md-start animated fadeIn mt-5">
                                    {config.apple
                                        ?
                                        (
                                            <a href={config.apple} className="bttn-image animated fadeIn " style={{ width: "275px", backgroundColor: config.mainColor }}  >
                                                <img src={AppleButton} alt="" id="apple" />
                                            </a>
                                        )
                                        : null}
                                    {config.google
                                        ?
                                        (
                                            <a href={config.google} className="bttn-image  animated fadeIn" style={{ width: "275px", backgroundColor: config.mainColor }} >
                                                <img src={AndroidButton} alt="" id="android" />
                                            </a>
                                        )
                                        : null}
                                </div>
                            </div>
                            <div className="d-flex m-sm-3 m-md-0 d-flex flex-column" >
                                <p className="text-center text-md-left" style={{ margin: '5px' }}>All major payment methods accepted</p>
                                <div className="row justify-content-center justify-content-md-start">
                                    <div>
                                        <img src={Visa} style={{ width: '50px', margin: '5px' }} alt="Visa" />
                                    </div>
                                    <div>
                                        <img src={Master} style={{ width: '50px', margin: '5px' }} alt="Master Card" />
                                    </div>
                                    <div>
                                        <img src={Amec} style={{ width: '50px', margin: '5px' }} alt="American Express" />
                                    </div>
                                    <div>
                                        <img src={ApplePay} style={{ width: '50px', margin: '5px' }} alt="Apple Pay" />
                                    </div>
                                    <div>
                                        <img src={GooglePay} style={{ width: '50px', margin: '5px' }} alt="Google Pay" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 d-flex justify-content-center" >
                            <div className=" animated fadeIn"  >
                                <div><img src={config.hero} alt="" id="hero" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

function LightenDarkenColor(col, amt) {
    //console.log("IN COLOR", col, amt)
    var usePound = false;
    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export default Header