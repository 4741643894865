
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/style.css';
import './css/animate.css';
import './css/icomoon.css';
//import './css/normalize.css';
import './css/responsive.css';
import Favicon from 'react-favicon';




import NavBar from './components/navBar'

import config from './config';


class Privacy extends React.Component {



  render() {


    return (
      <section className="section-padding mt-5" id="privacy_page"  >
        <div className="container">

          <div className="col-12">

            <div className="page-title text-center d-flex flex-column">
              <h5 className="title mb-5">Privacy</h5>
              <h1 className="section-title">Privacy Policy</h1>


              <h3>IMPORTANT LEGAL NOTICE</h3>
            </div>
            <div class="col-12">
              <p>
                We "{!config.clientRegName ? config.clientName : config.clientRegName}" at address "{config.clientRegAddress}"
                  are committed to protecting the privacy of all visitors to our Website, Mobile App or our online ordering channels ("Platform")
                  , including all visitors who access the Platform through any website or mobile application or any other platform or device.
              </p>
              <p>
                Please read the following Privacy Policy which explains how we use and protect your information. You fully agree and where required
                you consent to the collection, use and transfer of your information as set out in this policy.
              </p>
              <p>
                You also fully agree to the privacy policy of our supplier which can be found here:  
                <strong><a href="https://www.deptofdigital.com/privacy"> Dept Of Digital Privacy Policy</a></strong>. 
              </p>
              <p>
                In agreeing to this Privacy Policy you also agree to us providing your data to our Supplier on the terms set out in
                this Policy so that they may operate and run this service on our behalf.
              </p>
              <p>
                This privacy policy will replace any other Privacy Policy you may have seen or any other policy you may have been 
                sent by us. For example you may have seen
                a privacy policy on either the Apple or Google websites related to our service, this Privacy Policy takes precedent.
              </p>
              <h3>1. INFORMATION (THAT IS COLLECTED FROM YOU)</h3>
              <p>
                1.1. When you visit this Platform, Website or use the Service to make an order from us, you may be asked to provide the following
                information
                <ul>
                  <li>Name</li>
                  <li>User Name</li>
                  <li>Email Address</li>
                  <li>Delivery Address</li>
                  <li>Telephone Number</li>
                  <li>Payment Details such as Credit Card Details</li>
                </ul>
              </p>
              <p>
                1.2. By using the Platform, and when you place orders through the platform you will also provide details in your order that indicate your
                <ul>
                  <li>Food and drink preferences</li>
                  <li>Health Information</li>
                  <li>Religious Beliefs</li>
                  <li>Eating Habits</li>
                  <li>Ordering Frequency</li>
                  <li>Other information that can be derived from your order</li>
                  <li>Loyalty level and discount schemes usage</li>
                </ul>
              </p>
              <p>
                1.3. By using the Platform, the following data may be automatically collected
                <ul>
                  <li>IP Address</li>
                  <li>Browser Information</li>
                  <li>Device Identifiers</li>
                  <li>Cookie Data</li>
                  <li>Crash Data</li>
                  <li>Location</li>
                  <li>App Usage Data</li>
                </ul>
              </p>

              <p> 1.4 We may also collect information about your usage of the Website,
              Platform and Service and information about you from any materials (such as messages and reviews and telephone calls) you make,
              or post to the Website, Product, App or Service and the e-mails or letters or any other communication you send to us.
              </p>

              <p>
                1.5. By accessing this Platform or Website or Service or our App or using any of our other online ordering routes,
                then you should expect that our data collection and usage as set out in this Privacy Policy will apply in that context too.
                We may collect technical information from your mobile device or your use of the Website or the Service through a mobile device, for
                example, location data and certain characteristics of, and performance data about, your device,
                carrier/operating system including device and connection type, IP address, mobile payment methods,
                interaction with other retail technology such as use of NFC Tags, QR Codes or use of mobile vouchers. This information may
                be collected and used by us automatically if you use the Website or Service through your mobile device(s) or via
                our Suppliers who process our data on our behalf.
              </p>

              <h3>2. HOW WE USE YOUR INFORMATION</h3>

              <p>2.1 Your Personal Data is processed so that we can receive, process and fulfill your order with us</p>
              <p>2.3 Your Personal Data is also used so that we and our Data Processors can operate, improve and personalise our services to you.
              Reasons may include:
                <ul>
                  <li>To provide you with access to the relevant parts of the Platform, Website and to supply the Service.</li>
                  <li>To allow you to submit and complete orders with us</li>
                  <li>To allow us to communicate with you</li>
                  <li>To protect against fraud, illegal or harmful actions</li>
                  <li>To contact you for your views on the Website, App, Service or Platform and to notify you
                      occasionally about important changes or developments to the Website, App, Service or the Platform.</li>
                  <li>To bill you for the services provided</li>
                  <li>To respond to user queries</li>
                  <li>To provide you with ongoing support and or assistance</li>
                  <li>To comply with our legal or contractual obligations</li>
                  <li>To resolve legal disputes</li>
                  <li>To analyse your data</li>
                  <li>To enforce our terms and conditions</li>
                </ul>
              </p>
              <p>
                2.4. Where you have indicated accordingly, you agree that we may use your information to let you know about our other
                products and services that may be of interest to you including services that may be the subject of direct
                marketing and we may contact you to do so by post, telephone, mobile messaging (e.g. SMS, MMS, Push Notifications etc.) as well
                as by e-mail.
              </p>
              <p>
                2.5. Where you have indicated accordingly, you agree that we may also share information with third
                parties (including those in the food, drink, leisure, marketing and advertising sectors) to use your
                information in order to let you know about goods and services which may be of interest to you (by post,
                telephone, mobile messaging (e.g. SMS, MMS etc.) and/or e-mail) and to help us analyse the information we
                collect so that we can administer, support, improve and develop our business and services to you.
              </p>

              <h3>3. HOW OUR SUPPLIERS MAY PROCESS YOUR DATA</h3>
              <p>3.1 We use third party suppliers to operate and run this Service ("Our Data Processors"). These suppliers
              allow us to provide this Service to you. To allow this Service to function, our suppliers need access to your personal
              data. Our Data Processors are authorised by you and us to process your data as follows.
              </p>
              <ul>
                <li>To provide you with access to the relevant parts of the Platform, Website and to supply the Service.</li>
                <li>To allow you to access the platform, submit and complete orders</li>
                <li>To allow us to communicate with you</li>
                <li>To protect against fraud, illegal or harmful actions</li>
                <li>To contact you for your views on the Website, App, Service or Platform and to notify you
                      occasionally about important changes or developments to the Website, App, Service or the Platform.</li>
                <li>To bill you, or to bill you on our behalf, for the services provided</li>
                <li>To respond to user queries</li>
                <li>To provide you with ongoing support and or assistance</li>
                <li>To comply with their legal or contractual obligations</li>
                <li>To resolve legal disputes</li>
                <li>To enforce our and or their terms and conditions</li>
                <li>Our suppliers may also use and disclose your Personal Data to their own Data Processors to assist them in providing
                    this service to you</li>
                <li>To provide us with data anlaysis, sales data, purchase history and other data related to your use of the service</li>
                <li>To disclose or share your
                information in order to comply with (and/or where they believe they are under a duty to comply with) any
                      legal obligation</li>
                <li>To analyse, improve and enhance their service(s) that they provide</li>
                <li>To allow them to run and operate the service</li>
              </ul>
              <p>By submitting
                your personal data, you agree to this transfer, storing or processing of your personal data by our third party suppliers.</p>


              <h3>4. DISCLOSURE OF YOUR INFORMATION</h3>
              <p>
                4.1. The
                information you provide to us will be transferred to and stored on the servers of our suppliers which may be in or outside the
                European Economic Area, and may be accessed by or given to our staff working outside the United Kingdom and
                third parties (which means our subsidiaries and
                affiliates, our ultimate holding company and its subsidiaries and affiliates) who act for us for the purposes
                set out in this policy or for other purposes notified to you from time to time in this policy. Countries
                outside the European Economic Area do not always have strong data protection laws. However, we will always
                take
                steps to ensure that your information is treated in accordance with this policy.
              </p>
              <p>
                4.2. The third parties and suppliers with
                whom we share your information may undertake various activities such as, providing and running this online or ordering service,
                acting as an intermediary between you and us, processing credit card payments and
                providing support services for us, delivery services, hosting services,
                analysing data, billing, customer support and marketing assistance. By submitting
                your personal data, you agree to this transfer, storing or processing of your personal data by our third party suppliers.
                We will take all steps reasonably
                necessary to ensure that your data is treated securely and in accordance with this Privacy Policy.
              </p>
              <p>
                4.3. If you have consented we may allow carefully selected third parties, including marketing and advertising companies,
                our affiliates and associates, to contact you occasionally about services that may be of interest to you.
                They may contact you by post, telephone, mobile messaging (e.g. SMS, MMS, Push Notifications etc.) as well as by e-mail. If you
                change your mind about being contacted by these companies in the future, please let us know.
              </p>
              <p>
                4.4. If our business enter into a joint venture with, purchases or is sold to or merged with another business entity,
                your information may be disclosed or transferred to the target company, our new business partners or owners or
                their advisors.
              </p>
              <p>
                4.5.
                We may use the information that you provide to us if we are under a duty to disclose or share your
                information in order to comply with (and/or where we believe we are under a duty to comply with) any
                legal obligation;
              </p>

              <h3>5. Lawful Basis For Processing</h3>
              <p>You agree to the interpretation below of the Lawful Basis for the processing of your data. Where relevant the Lawful Basis
                below apply to both us and our Data Processors.
              </p>
              <p>Administration Purposes</p>
              <ul>
                <li>This processing is necessary for the performance of a contract between you and us 
                , where necessary for the purpose of complying with our legal obligations and 
                where you have consented to providing certain information in relation to your food order 
                preferences at the time of purchase.
                </li>
              </ul>
              <p>Training, quality monitoring, enhancing or evaluating the provided services</p>
              <ul>
                <li>This processing is necessary for the legitimate interests pursued by us and our Data Processors in monitoring and 
                improving these services and their usage and ensuring that our users use the Platform 
                in accordance with our terms and conditions and policies.
              </li>
              </ul>
              <p>Website services, including for troubleshooting, data analysis, and survey purposes.</p>
              <ul>
                <li>We and our Data Processors have a legitimate interest in operating and maintaining the Platform and for 
                  related purposes including improving our services and providing you with support.
                </li></ul>
              <p>Statistical information that cannot be related back to individuals to help us improve the services we offer. </p>
                <ul>
                  <li>
                      We and our Data Processors have a legitimate interest in carrying out certain analytics to ensure the services we or they provide 
                      are effective and operate appropriately.
                  </li>
                </ul>
              <p>Enforcing and Defending Our Rights</p>
              <ul>
                <li>We and our Data Processors have a legitimate interest in ensuring that our services and the Website are used in accordance with our 
                terms and conditions of use and policies. Where necessary for the purpose of complying with our legal obligations. 
                Where necessary for the purpose of establishing, exercising or defending a legal claim, a prospective legal claim, 
                legal proceedings or prospective legal proceedings.
              </li>
              </ul>
              <p>Urgent or Unforeseeable Events</p>
              <ul>
                <li>From time to time, certain events outside of our control may create a situation where we need to contact you
                  urgently. During such times we are allowed to process your data in order to protect the vital interests of the data subject or 
                  another person.
              </li>
              </ul>

              <h3>5. SECURITY AND DATA RETENTION</h3>
              <p>
                5.1. We take steps to protect your information from unauthorised access and against unlawful processing, 
                accidental loss, destruction and damage. We will keep your information for a reasonable period or as 
                long as the law requires.
              </p>
              <p>
                5.2. Where you have chosen a password which allows you to access certain parts of the Platform, you are
                responsible for keeping this password confidential. We advise you not to share your password with anyone.
                Unless we negligently disclose your password to a third party, we will not be liable for any unauthorised
                transactions entered into using your name and password.
              </p>
              <p>
                5.3. The transmission of information via the internet
                is not completely secure. Although we will take steps to protect your information, we cannot guarantee the
                security of your data transmitted to the Website; any transmission is at your own risk. Once we have received
                your information, we will use strict procedures and security features to try to prevent unauthorised access.
              </p>

              <h3>5. ACCESSING AND UPDATING</h3>
              <p>
                5.1. You have the right to see the information we hold about you ("Access Request") and
                to ask us to make any changes to ensure that it is accurate and up to date. If you wish to do this, please
                contact us using the contact details set out in paragraph 7 below. In the event that you make an Access
                Request, we reserve the right to charge a fee of no less than twenty five pounds (£25.00) to meet our costs in providing you with
                details of the information we hold about you.
              </p>

              <h3>6. CHANGES TO OUR PRIVACY POLICY</h3>
              <p>
                6.1. Any changes to our Privacy
                Policy will be posted to this Website please check this website regulary for updates.
              </p>

              <h3>7. CONTACT</h3>
              <p>
                7.1. All
                comments, queries and requests relating to our use of your information are welcomed and should be addressed
                to us directly.
              </p>

            </div>

          </div>

        </div>
      </section>
    );
  }





}

export default Privacy

