
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/style.css';
import './css/animate.css';
import './css/icomoon.css';
//import './css/normalize.css';
import './css/responsive.css';
import Favicon from 'react-favicon';




import NavBar from './components/navBar'

import config from './config';


class Terms extends React.Component {



  render() {


    return (
      <section className="section-padding mt-5" id="terms_page"  >
        <div className="container">

          <div className="col-12">

            <div className="page-title text-center d-flex flex-column">
              <h5 className="title mb-5">Terms</h5>
              <h1 className="section-title">Terms & Conditions</h1>
              <h3>IMPORTANT LEGAL NOTICE</h3>
              <p className="text-left col-12">
                This page sets out the terms and conditions ("Platform Terms") on which
                we ("we" , us, our or "{!config.clientRegName ? config.clientName : config.clientRegName}"),
                at address ("{config.clientRegAddress}") provide use of and access to our services,
                including without limitation access to any websites, mobile apps, products, applications,
                tools and features provided by our Suppliers.
              </p>
              <p className="text-left col-12">By agreeing to these Platform Terms, you are also agreeing to the Terms & Conditions of out Supplier who provides
              and operates this platform on our behalf. Thier Terms and Conditions are available here.
                <strong><a href="https://www.deptofdigital.com/enduserterms"> Dept Of Digital Terms</a> </strong>
              </p>
              <p className="text-left col-12">
                "Platform" means this Website and/or Mobile Ordering Application that you use for placing orders with us
                and any associated websites or other applications provided by our Supplier (Dept Of Digital) that are used
                to access our services and or orders goods from us.
              </p>
              <p className="text-left col-12">
                Please read
                these Platform Terms carefully before ordering any services or products through, the Platform.
               </p>
            </div>

            <div className="col-12">

              <h3>
                1. PLATFORM ACCESS TERMS
              </h3>
              <p>
                1.1. Platform access: You may access some areas of the Platform without making an Order or
                registering your details with us.
              </p>
              <p>
                1.2. Acceptance of terms: By accessing this Platform, or by creating an Account on this platform or by placing an order with us
                for any of our products or services via this Platform, you indicate that
                you accept these Platform Terms. If you do not accept these Platform Terms, you should leave our Platform
                (inc this Website or Mobile App) and any of our Services imediately, and you will not be able to order any Services or Products
                through the Website or Platform, upon returning we will assume you intend to accept these terms.
               </p>
              <p>
                1.3. Revision of terms: We may revise these Platform Terms at any time. You should check the Platform
                regularly to review the current Platform Terms, because they are binding on you. You will be subject to the policies
                and terms and conditions in force at the time that you place an Order through us.
              </p>
              <p>
                1.4. Responsibility: You are responsible for making all arrangements necessary for you to have access to the
                Services. You are also responsible for ensuring that all persons who access the Services through your
                Internet connection are aware of these Platform Terms and that they comply with them.
              </p>

              <h3>2. OUR PURPOSE</h3>
              <p>
                2.1. We provide this platform (our “Ordering Platform”) so that you may initiate and pay for an order ("Orders")
                for products ("Products") directly with us.
              </p>


              <h3>3. YOUR STATUS</h3>
              <p>
                3.1. Capacity and age: By placing an Order for our Services with us, you warrant that:
              <ul class="list-unstyled">
                  <li>2.1.1. You are legally capable of entering into binding contracts; </li>
                  <li>2.1.2. You are at least 18 years old.</li>
                </ul>
              </p>


              <h4>4. USING THIS SERVICE AND/OR PLATFORM</h4>
              <p>4.1 Use for Convenience. This Platform and or Service is not intended to be relied upon by you.
              We cannot guarantee availability of the Platform or Services at all times nor the accuracy or appropriateness of information.
              In particular, you should make your own checks on the accuracy of your order and the amount you are paying.
                </p>

              <p>4.2 We make no guarantee, accept no responsibility or liability that your order will be received by us via our Platform.
              This is because the internet is a medium that does not guarantee communication and we are not in control of every
              step between your phone and the Venue.
              </p>

              <p>4.3 We accept no responsibility or liability for any errors or omissions within the Service or Platform.
              You accept that in using the Platform, that errors and defects can occur and errors and defects are entirely normal
              in such systems or our processes to maintain them. We cannot accept any responsibility or liability as a result of error or
              defect or a behaviour of the system that is not to your expectation.
              </p>

              <p>4.4 All questions regarding goods shown on this Platform should be directed to us. Acceptance of your order will only
              occur when you have received your order and we hace been paid by you for the order.
              If we are unable to fulfil your order you may receive an email/SMS/phone call or other notification stating that your order
              has been declined or cannot be fulfilled.
              </p>

              <p> 4.8 Incorrect personal details may lead to problems or delays in delivery, so please ensure that you have included your
              correct address and contact telephone number when ordering.
              </p>

              <h4>5. DATA CONTROLLER / PROCESSOR</h4>
              <p>We are the Data Controller. To provide the online elements of this service, inc this Website, Mobile Ordering App we use
              third party Suppliers to operate and run the Platform. Our Suppliers are Data Controllers acting always under our full instructions.
              </p>

              <h3>6. CREATING AN ACCOUNT</h3>
              <p>6.1 You may need to create an account before you place an order. When you do, your data will be accessible by us and
              our Suppliers. We and or our Suppliers may be able to access your data for support services in assisting us as required.
              In this regard we are the Data Controller and our Suppliers are the Data Processor.
              </p>
              <p>
                6.2 The platform requests a minimal amount of data about you. You agree not to enter into the Platform any data or information about you,
                that you deem Private or Confidential. All data entered into the Platform, will be treated as non private and non-confidential
              </p>



              <h3>7. PRICING</h3>
              <p>
                7.1. VAT and delivery costs: Prices will be as quoted on the Website or within the App.
                These prices include VAT but may exclude delivery costs unless you opt for delivery instead of collection.
                Other costs such as any administration or service charges we levy will also be added to the total amount due.
              </p>
              <p>
                7.2. Incorrect pricing: It is possible that our prices may be incorrect. We may determine at any time if our
                published or quoted prices are incorrect. In such an event we are not under any obligation to ensure that the
                Order is provided to you at the incorrect price or to compensate you in respect of incorrect pricing.
              </p>
              <p>
                7.3 Payment for Orders must be made by an accepted credit or debit card through the App by you.
                Payment is taken before we receive the order.
              </p>
              <p>
                7.4 If you pay by credit or debit card, you may be required to show the card to us at the time of delivery or collection
                as proof of identification and so that we can check that the card corresponds with the receipt data for the Order.
                Please note that from time to time there may be delays with the processing of card payments and transactions;
                this may result in payments taking up to sixty (60) days to be deducted from your bank account or charged to
                your credit or debit card.
              </p>
              <p>
                7.5 Credit and discount vouchers: A credit or discount may apply to your Order if you use a promotional voucher or code recognised
                by the Platform, and you pay for any balance by credit or debit card. Please note that because of standard banking procedures,
                your bank or card issuer may initially "ring-fence" the full amount of the Order (before any credit or discount) in your account
                for between 3 to 5 working days (or longer, depending on your bank or card issuer), and this amount will therefore be
                unavailable in your account for that period. The credit or discount will be applied at the time your bank or card issuer
                transfers the funds for your Order to us, at which point the credit or discounted amount will not be transferred to us and
                will instead be released by your bank or card issuer back into your available balance. You acknowledge and agree that
                neither we nor our Suppliers (e.g Dept Of Digital) will be responsible or liable to you in relation to this delay by your
                bank or card issuer in the release of funds back into your account.
              </p>
              <p>
                7.6 Rejected Orders: Because of standard banking procedures, once you have submitted an Order that you are paying for by credit
                or debit card and your payment has been authorised, your bank or card issuer will "ring-fence" the full amount of your Order.
                If your Order is subsequently rejected by us or cancelled for any other reason, your bank or card issuer will not transfer
                the funds for the Order to us, and will instead release the relevant amount back into your available balance. However,
                this may take between 3 to 5 working days (or longer, depending on your bank or card issuer).
                You acknowledge and agree that neither we nor our Suppliers (e.g Dept Of Digital) will be responsible or liable to you
                in relation to this delay by your bank or card issuer in the release of funds back into your account.
              </p>
              <p>
                7.7 Refunds: If we provide you with a refund, due to our payment merchant withholding a transaction charge for each order
                placed you agree that in all cases this transaction charge and any other charges deducted from your
                order by our suppliers will not be included in the refund. Our Suppliers will in no circumstances be liable to you for
                any element of a refund that is requested, or due to you.
               </p>


              <h3>8. LICENCE</h3>
              <p>
                8.1. Terms of permitted use: You are permitted to use the Platform and Website or App and print and download
                extracts
                from the Website for your own personal non-commercial use on the following basis:
                <ul class="list-unstyled">
                  <li>8.1.1. You must not misuse the Website (including by hacking or "scraping").</li>
                  <li>8.1.2. Unless otherwise stated, the copyright and other
                  intellectual property rights in the Website and Platform and in material published on it (including without
                  limitation photographs and graphical images) are owned by us, our Suppliers (e.g Dept Of Digital) or our licensors. These works are protected by
                  copyright laws and treaties around the world and all rights are reserved. For the purposes of these Platform
                Terms, any use of extracts from the Website other than in accordance with paragraph 4.1 is prohibited.</li>
                  <li>8.1.3.
                  You must not modify the digital or paper copies of any materials that you print off in accordance with
                  paragraph 4.1 and you must not use any pictures, photographs or any other graphics, video or audio sequences
                separately from any accompanying text.</li>
                  <li>8.1.4. You must ensure that our status as the author of the material
                  on
                the Website is always acknowledged.</li>
                  <li>8.1.5. You are not allowed to use any of the materials on the Website or
                the Website itself for commercial purposes without obtaining a licence from us to do so.</li>
                </ul>
              </p>
              <p>
                8.2. Limitation on use: Except as stated in paragraph 4.1, this Website nor any other part of the Platform may not be used, and no part of the Website may be
                reproduced or stored in any other website or included in any public or private electronic retrieval system or
                service, without our prior written permission.
                </p>
              <p>
                8.3. Reservation of rights: Any rights not expressly granted in
                these Platform Terms are reserved.
              </p>

              <h3>9. INTELLECTUAL PROPERTY</h3>
              <p>9.1 You may not republish, display, modify, broadcast, print, copy, reproduce, download, transmit or re-use
              the materials from this Dept Of Digital Platform for anything other than personal, non-commercial use, including in
              particular any purpose which publicly re-sells or re-uses the materials, without the prior written consent of the
              copyright holder.
                </p>

              <p>9.2 All copyright, trademarks and other intellectual property rights in this Platform and its content (including without
              limitation its design, text, graphics, logos, icons, images and all software, databases and
              source code connected with us or our suppliers are owned by or licensed us or our Suppliers.
              </p>


              <h3>10. SERVICE ACCESS</h3>
              <p>
                10.1. Platform and Website availability: While we try to ensure our services including the Website and Apps available
                twenty four (24) hours a day, we do not undertake any obligation to do so, and we will not be liable to you
                if the Website or any of our Services is unavailable at any time or for any period.
               </p>
              <p>
                10.2. Suspension of access: Access to our Services or this Platform may be suspended temporarily at any time and without notice.
              </p>
              <p>
                10.3. Information security: The
                transmission of information via the internet is not completely secure. We cannot guarantee the security of
                your data transmitted to the Website or our Services or the Platform or if any element of our Services transmits data to other Services and or to third parties (e.g Facebook); any
                transmission is at your own risk.
              </p>

              <h3>11. VISITOR MATERIAL AND REVIEWS</h3>
              <p>
                11.1. General: 11.1.1. Other than personally identifiable information, which is covered under our Privacy
                Policy,
                any material you post, upload or transmit or upload to our Service (including without limitation Reviews)
                ("Visitor Material") will be considered non-confidential and non-proprietary. By posting, uploading or
                transmitting any Visitor Material, you represent and warrant that you own or otherwise control all of the
                rights to such Visitor Material. You agree that we will have no obligations with respect to any Visitor
                Material, and that we and anyone we designate will be free to copy, disclose, distribute, incorporate and
                otherwise use any Visitor Material and all data, images, sounds, text and other things embodied in it for any
                and all commercial or non-commercial purposes. 11.1.2. You represent and warrant that that any Visitor
                Material
                you post, upload or transmit does not and will not breach any of the restrictions in paragraphs 11.2 to 11.3
                below.
        </p>
              <p>
                11.2. Visitor Material Policy: You are prohibited from posting, uploading or transmitting to or from the
                Website any Visitor Material (including any Reviews) that: 11.2.1. breaches any applicable local, national or
                international law; 11.2.2. is unlawful or fraudulent; 11.2.3. amounts to unauthorised advertising; or 11.2.4.
                contains viruses or any other harmful programs.
        </p>
              <p>
                11.3. Visitor Reviews Policy: In particular (but without
                limitation), any Reviews that you submit through the Website must not: 11.3.1. contain any defamatory, obscene
                or offensive material; 11.3.2. promote violence or discrimination; 11.3.3. infringe the intellectual property
                rights of another person; 11.3.4. breach any legal duty owed to a third party (such as a duty of confidence);
                11.3.5. promote illegal activity or invade another's privacy; 11.3.11. give the impression that they originate
                from us; or 11.3.7. be used to impersonate another person or to misrepresent your affiliation with another
                person.
        </p>
              <p>
                11.4. Removal of Reviews: The prohibited acts listed in paragraphs 11.2 and 11.3 above are non-exhaustive.
                We reserve the right (but do not undertake, except as required by law, any obligation) and have the sole
                discretion to remove or edit at any time any Reviews or other Visitor Material posted, uploaded or
                transmitted
                to the Website or Platform that we determine breaches a prohibition in paragraphs 11.2 or 11.3 above, is
                otherwise objectionable or may expose us or any third parties to any harm or liability of any type, or for
                any
                other reason.
        </p>
              <p>
                11.5. Use of Reviews: The Reviews and other Visitor Material contained on the Website or Platform
                are for information purposes only and do not constitute advice from us. Reviews and Visitor Material reflect
                the opinions of customers who have ordered through the Website or other third parties, and any statements,
                advice or opinions provided by such persons are theirs only. Accordingly, to the fullest extent permitted by
                law, we assume no responsibility or liability to any person for any Reviews or other Visitor Material,
                including without limitation any mistakes, defamation, obscenity, omissions or falsehoods that you may
                encounter in any such materials.
        </p>
              <p>
                11. Liability: You agree to indemnify us against any losses, damages and
                claims (and all related costs) incurred by or made against us by you or any other third party
                arising
                out of or in connection with any Reviews or other Visitor Material that you provide in breach of any of the
                representations and warranties, agreements or restrictions set forth in this paragraph 11.
        </p>
              <p>
                11.7. Disclosure to
                authorities and courts: You acknowledge that we will fully co-operate with any competent authority requesting
                or directing us to disclose the identity or location of anyone posting any Reviews or other Visitor Material
                in
                breach of paragraph 11.2 or 11.3 or any other applicable restriction and you release us to the fullest extent
                permitted by law from all liability in relation to such disclosure.
        </p>

              <h3>18. LINKS TO AND FROM OTHER WEBSITES</h3>
              <p>
                18.1. Third party websites: Links to third party websites on the Website are provided solely for your
                convenience. If you use these links, you leave the Website. We have not reviewed and do not control any of
                these third party websites (and are not responsible for these websites or their content or availability). We
                do
                not endorse or make any representation about these websites, their content, or the results from using such
                websites or content. If you decide to access any of the third party websites linked to the Website, you do so
                entirely at your own risk.
        </p>
              <p>
                18.2. Linking permission: You may link to our Website's homepage,
                provided that: 18.2.1. you do so in a fair and legal way which does not damage or take advantage of our
                reputation; 18.2.2. you do not establish a link from a website that is not owned by you or in a way that
                suggests a form of association with or endorsement by us where none exists; 18.2.3. any website from which
                you link must comply with the content standards set out in these Website Terms (in particular paragraph 9
                (Visitor
                Materials and Reviews)); 18.2.4. we have the right to withdraw linking permission at any time and for any
                reason.
        </p>

              <h3>19. DISCLAIMERS</h3>
              <p>
                19.1. Website and or platform information: While we try to ensure that information on the Website and
                Platform is correct, we do not promise it is accurate or complete. We may make changes to the material on the Platform
                or Website, or to the Service, Products and prices described on it, at any time without notice. The material
                on the Website may be out of date, and we make no commitment to update that material.
        </p>
              <p>
                19.2. Allergy, dietary and other menu information: If you have, or someone you are ordering for has, a concern about food allergies,
                intolerances or other dietary preferences, you should always contact us directly before placing an order. If you have any Allergen
                or food intolerances, or anyone you are ordering for has, or may have allergen or food intolerances and are unable to contact us
                to clarify your Allergen or food intolerance information, you must not order.
        </p>
              <p>
                19.3. Our actions and omissions: The
                legal contract for the supply and purchase of Products ordered with us, is between you and us and does not include our Supplier (Dept Of Digital) as they have no control over
                the actions or omissions of us our you. Without limiting the
                generality of the foregoing, you acknowledge and accept the following by using the Website and Platform:
                19.3.1. We and our Supplier Dept Of Digital does not give any undertaking that the Products ordered through the Platform by you
                will be of satisfactory quality or suitable for your purpose and we disclaim any such warranties.
        </p>
              <p>
                19.4. Exclusion of terms: We provide you with access to the Website, Platform and Service on the basis that, to the maximum extent
                permitted by
                law, we and our Suppliers ( inc Dept Of Digital) exclude all representations, warranties, conditions, undertakings and other terms in relation to the
                Website, Platform and Service (including any representations, warranties, conditions, undertakings and other
                terms which might otherwise apply to the Website or Service, or be otherwise implied or incorporated into
                these Platform Terms, by statute, common law or otherwise ).
        </p>

              <h3>20. LIABILITY</h3>
              <p>
                20.1. General: Nothing in these Platform Terms excludes or limits our liability for death or personal injury
                arising from our fraudulent misrepresentation. Nothing in these Website Terms affects your statutory
                rights.
              </p>

              <p>
                20.2 We nor our Suppliers (inc Dept Of Digital) will not be liable, whether in contract, tort (including negligence),
                breach of statutory duty to you or any third party for any loss, cost,
                damage or other liability whatsoever (and whether direct, indirect, special, consequential or punitive) arising out
                of or in connection with the Platform.
              </p>

              <p>
                20.3. Limitation of liability: Subject to clauses 20.1 our total liability
                to you in respect of all other losses arising under or in connection with the Service or the Website, whether
                in contract, tort (including negligence), breach of statutory duty, or otherwise, shall in no circumstances
                exceed the greater of £50 or the value of your most recent order.
                Our Suppliers (inc Dept Of Digital) liability to you in respect of any or other losses arising under or in connection with the
                Service or the Website, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, shall in no
                event exceed the lesser of £50 or the commission they have received from us from your orders with us in the 6 months prior to your claim. 
        
              </p>
              <p>
                20.4. Additional costs: You assume full and
                sole responsibility for any additional or associated costs that you may incur in connection with or as a
                result of your use of the Website, our Platform or any of our services, including without limitation costs relating,
                the sign-up or on-boarding process, use of the platform or costs relating to the servicing, repair or
                adaptation of any equipment, software or data that you may own, lease, license or otherwise use.
              </p>

              <h3>21. INDEMNITY</h3>
              <p>21.1 YOU MUST INDEMNIFY AND HOLD HARMLESS DEPT OF DIGITAL, ITS DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, VENUES, ADVERTISERS,
              PARTNERS AND SUPPLIERS AND KEEP THEM INDEMNIFIED AGAINST ALL LOSS, ACTIONS, PROCEEDINGS, COSTS, EXPENSES (INCLUDING LEGAL FEES),
              CLAIMS AND DAMAGES ARISING FROM YOUR ACCESS TO AND USE OF THIS PLATFORM, ANY (DIRECT OR INDIRECT) FOLLOW ON EVENT THAT OCCURS
              FOLLOWING ON FROM YOUR ACCESS TO THIS PLATFORM, ANY BREACH OR VIOLATION OF THIS AGREEMENT, OR ANY LEGAL ACTION BROUGH BY YOU
              FOLLOWING YOUR USE OF THIS PLATFORM OR YOUR VIOLATION OF ANY
              LAW OR YOUR VIOLATION OF THE RIGHTS OF ANY THIRD PARTY.
              </p>

              <h3>22. TERMINATION</h3>
              <p>
                22.1. Grounds for termination: We may terminate or suspend (at our absolute discretion) your right to use the
                Website, Platform and the Service immediately if we believe
                in our sole discretion that: 22.1.1. you have used the Platform or Website in breach of paragraph 7.1 (License);
                22.1.2. you have posted Reviews or other Visitor Material in breach of paragraphs 9.2 or 9.3 (Visitor
                Material and Reviews); 22.1.3. you have breached paragraph 7.2 (Links to and from other websites); or 22.1.4. you
                have breached any other material terms of these Platform Terms ; or 13.1.5. you do not pay your fees as they fall due
        </p>
              <p>
                22.2. Obligations upon termination: Upon termination
                or suspension you must immediately destroy any downloaded or printed extracts from the Website or Platform.
        </p>

              <h3>23. WRITTEN COMMUNICATION</h3>
              <p>
                23.1. Applicable laws require that some of the information or communications we send to you should be in
                writing. When using the Website or Platform or ordering Products via the Website or Platform, you accept that
                communication with us will be mainly electronic. We will contact you by email or provide you with information
                by posting notices on the Website. For contractual purposes, you agree to this electronic means of
                communication and you acknowledge that all contracts, notices, information and other communications that we
                provide to you electronically comply with any legal requirement that such communications be in writing. This
                condition does not affect your statutory rights.
        </p>

              <h3>24. EVENTS OUTSIDE OUR CONTROL</h3>
              <p>
                24.1. Both we and our Suppliers (inc Dept Of Digital) will (individually or together), not be liable or responsible for
                any failure to perform, or delay in performance of, any of our
                obligations under these Website Terms that is caused by events outside our reasonable control ("Force Majeure
                Event").
               </p>
              <p>
                24.2. A Force Majeure Event includes any act, event, non-occurrence, omission or accident beyond our
                reasonable control and includes in particular (without limitation) the following: 24.2.1. strikes, lock-outs
                or
                other industrial action; 24.2.2. civil commotion, riot, invasion, terrorist attack or threat of terrorist
                attack, war (whether declared or not) or threat or preparation for war; 24.2.3. fire, explosion, storm,
                flood,
                earthquake, subsidence, epidemic or other natural disaster; 24.2.4. impossibility of the use of railways,
                shipping, aircraft, motor transport or other means of public or private transport; 24.2.5. impossibility of
                the
                use of public or private telecommunications networks; and 24.2.6. the acts, decrees, legislation, regulations
                or restrictions of any government.
        </p>
              <p>
                24.3. Our performance under these Website Terms is deemed to be suspended
                for the period that any Force Majeure Event continues, and we will have an extension of time for performance
                for the duration of that period. We will use our reasonable endeavours to bring any Force Majeure Event to a
                close or to find a solution by which our obligations under these Website Terms may be performed despite the
                Force Majeure Event.
              </p>

              <h3>25. ADDITIONAL TERMS</h3>
              <p>
                25.1. Our Privacy Policy: We are committed to protecting your privacy and security. All personal data that we
                collect from you will be processed in accordance with our Privacy Policy. You should review our Privacy
                Policy which can be fouind here. 
                <br/><strong><a href="/privacy">Privacy Policy</a></strong>.
              </p>
              <p>
                25.2. Our Suppliers Terms And Conditions: We use a third party supplier to operate and run the online ordering
                platform that we use. You must also agree to their terms and conditions which can be found here.
                <br/>
                <strong><a href="https://www.deptofdigital.com/enduserterms"> Dept Of Digital Terms</a> </strong>
              </p>
              <p>
                25.3. Our Suppliers Privacy Policy: You must also agree to the privacy policy of our Supplier which can be found here.
                <br/><strong><a href="https://www.deptofdigital.com/privacy"> Dept Of Digital Privacy Policy</a></strong>.
              </p>

              <p>
                25.4. Severability: If any of these Platform Terms are determined by any
                competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision
                will to that extent be severed from the remaining terms, conditions and provisions which will continue to be
                valid to the fullest extent permitted by law.
              </p>
              <p>
                25.5. Entire agreement: These Platform Terms and any document
                expressly referred to in them constitute the whole agreement between you and us and supersede all previous
                discussions, correspondence, negotiations, previous arrangement, understanding or agreement between us
                relating to the subject matter of any contract.
              </p>
              <p>
                25.6. No waiver: Any failure or delay by you or us in enforcing (in
                whole or in part) any provision of these Platform Terms will not be interpreted as a waiver of your or our
                rights or remedies.
              </p>
              <p>
                25.7. Assignment: You may not transfer any of your rights or obligations under these
                Platform Terms without our prior written consent. We may transfer any of our rights or obligations under
                these Platform Terms without your prior written consent to any of our affiliates or any business that we 
                enter into a joint venture with, purchase or are sold to.
              </p>
              <p>
                25.8. Headings: The headings in these Platform Terms are included
                for convenience only and shall not affect their interpretation.
              </p>

              <h3>26. COMPETITIONS</h3>
              <p>
                26.1 We may from time to time offer competitions. Our competition(s) may be made available on our website or
                social media
                platforms, such as Facebook.
        </p>

              <p>
                26.2 To enter our competition you must be over 18 years old.
        </p>

              <p>
                26.3 By entering any of our competitions, the entrant (you) agrees to these terms and conditions as well as
                our General Terms and Conditions
        </p>


              <p>
                26.3 By entering any of our competitions, the entrant (you) agrees to these terms and conditions
        </p>

              <p>
                26.4 The winner of the competition agrees to the use of his/her name and image in any publicity material, as
                well as their entry.
        </p>

              <p>
                26.5 We will judge the competition and decide on the winner of each competition. Some competitions require a
                minimum number of entrants until a winner is annouced. Where the minimim number of entrants is not completed,
                we may not annouce a winner.
        </p>

              <p>
                26.6 We reserve the right, at our sole discretion and at any time, to change or modify these terms and
                conditions, such change shall be effective immediately upon posting to this webpage.
        </p>

              <p>
                26.7 Our competitions are in no way sponsored, endorsed or administered by, or associated with, Facebook,
                Twitter or any other Social Network.
        </p>

              <p>
                26.8 Our decision is final and binding in all matters relating to any competition and no correspondence will
                be entered into.
        </p>

              <p>
                26.9 To enter our competition you must be over 18 years old
        </p>

              <p>
                26.10
                Competition Terms and any applicable Specific Competition Terms shall be governed by the laws of England and
                Wales and subject to the exclusive jurisdiction of the English courts.
        </p>
              <p>
                26.11 You may enter our competition by following the rules as described on the Competition Page. If there are
                no rules you may contact us at our email address for further instruction
        </p>

              <p>
                26.12 For the avoidance of doubt, we are not responsible or liabile to you for any loss, costs however they
                may arise as a result of any of our compeitions or in the use of any of our services.
        </p>


              <p>
                26.2 We reserve right to cancel or amend the competition and these terms and conditions without notice
        </p>

        <h3>27. REWARDS, LOYALTY POINTS & DICOUNTS</h3>
        <p>
         27.1 Any loyalty points received by using the system are entirely at our discretion.    
         </p>
         <p>
         27.2 We may remove alter or edit the loyalty points balance at any time, and at our complete discretion.
         </p>
        <p>
          27.3 No guarantee is given of successful results from the use of our Loyalty Points syste, Discounts System or Rewards System
        </p>
        <p>
          27.4 We reserve the right to refuse you from redeeming previously accrued
          Rewards, Loyalty Points or Discounts.
        </p>
        <p>
          27.5 You may not convert your Rewards, Loyalty Points or Discounts into any other form of monetary payment.
        </p>
        <p>
          27.6 No right of compentsation or liability shall be available to you should any Reward, Loyalty Points Balance or Discounts be
          unavailable to you, however that unavailability might occur.
        </p>
        <p>
          27.7 Any rewards, loyalty points (or loyalty points balance) or discounts has no cash value until agreed to be redeemed by us. 
        </p>
        <h3>28. GOVERNING LAW AND JURISDICTION</h3>
        <p>
            28.1 These Website Terms shall be governed by and construed in accordance with English law. Disputes or
            claims arising in connection with these Platform Terms (including non-contractual disputes or claims) shall be
            subject to the exclusive jurisdiction of the English courts.
        </p>


            </div>
          </div>

        </div>
      </section>
    );
  }





}

export default Terms

