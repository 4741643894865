
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/style.css';
import './css/animate.css';
import './css/icomoon.css';
//import './css/normalize.css';
import './css/responsive.css';
import Favicon from 'react-favicon';
import Footer from './components/footer'
import { Helmet } from "react-helmet";
import config from './config.js';


import NavBar from './components/navBar'

import App from './app'
import Terms from './terms'
import Privacy from './privacy'
import Menu from './menu'
import MagicLink from './magicLink'

class Index extends React.Component {


  componentDidMount() {

  }

  render() {

 const currentPath = window.location.pathname ? window.location.pathname : null

    return (
        
      <Router>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{config.clientName} - Order Online</title>
          <meta name={`${config.clientName}`} content="Download our app" />
          <meta property="og:title" content={`Download our app`}/>
          <link rel="apple-touch-icon" href={config.logo} />
          <meta property="og:image" content={`${config.hero}`} />
          <meta property="og:description" content={`Order Online With Our App`} />

          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        </Helmet>
        <Favicon url={config.favicon} />
        <div>
        {this.showNavBar(currentPath)}
          <Route exact path="/" component={App} />
          <Route path="/menu/:shopId" component={Menu} />
          <Route path="/order" component={MagicLink}  />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Privacy} />
        </div>
        <Footer />
      </Router>
       
    );
  }

  showNavBar(currentPath) {
    if (currentPath !== "/order") {
     return <NavBar  handleSelectShop={this.handleSelectShop}/>
  }

  return null
  }

}

ReactDOM.render(<Index />, document.getElementById('root'));
