
import React, { Component } from "react"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isIOS,
  isAndroid,
  isMacOs
} from "react-device-detect";
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import config from "../config";
import AppleButton from '../images/apple_button.png'
import AndroidButton from '../images/google_button.png'
import Loader from '../animations/rings'
import {Animated} from "react-animated-css";


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding: "50px",
    border: "none",
    boxShadow: "10px 10px 44px -9px rgba(43,43,43,1)",
  },

};

class Menu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      menu: {},
      loaded: false,
      showOpeningSection: true,
      modalOpen: false,
      installLink: ""
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }

  componentDidMount () {
  console.log("Component did mount")

  if (isAndroid) this.setState({installLink: config.google})
  if (isIOS) this.setState({installLink: config.apple})

  const loadAppData = (clientKey, version) => {

      return new Promise((resolve, reject) => {
        // return resolve(require('./mockState.json'))
        fetch(`https://us-central1-ordrcoffee.cloudfunctions.net/getShopData?version=${version}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            client_key: clientKey
          })
        })
          .then((response) => response.status !== 204 ? response.json() : null)
          .then (json => {

            this.setState({menu: json,loaded: true})
          })
          .then(resolve)
          .catch(reject);
      });
    }


  loadAppData(config.client)   //client key
  }


  
  render() {
    
    // console.log("STATE", this.state.setShow)
    // console.log("MENU",this.state.menu)
    // console.log("CONFIG",config)
    // console.log("INSTALLLINK",this.state.installLink)

    const shopName = this.state.menu.name
    const menu = this.state.menu
    const currentShopKey = this.props.currentShopKey
    const backgroundColor = this.props.backgroundColor ? this.props.backgroundColor : "white"

    if (!this.state.loaded) {
    return  (
      <div className="container d-flex flex-column align-items-center col-12">
        <Loader  stroke={"#606060"}/>
        <div>Loading Menu</div>
      </div>
  )
    }

    return (
      <Animated animationIn="fadeIn" animationInDuration="1000" animationOut="fadeOut" isVisible={true}>
        <div className="container" style={{background: backgroundColor}}>
         {this.showShops(menu,this.state.showOpeningSection,currentShopKey)}
        </div>
      </Animated>
    );
  }

openModal() {
 this.setState({modalOpen: true})
  }

closeModal() {
  this.setState({modalOpen: false})
}

showShops (menu,showOpeningSection,currentShopKey) {
     
      if (!currentShopKey) return <div>NO SHOP FOUND</div>;

      const shop = menu.shops[currentShopKey]
      return (
        <div className="pb-5">
          <Modal
          isOpen={this.state.modalOpen}
          //onAfterOpen={afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="App Message Modal"
        >
 
 
          <div>To order from us please Download our app onto your smartphone</div>
          <div className="row justify-content-center justify-content-md-start animated fadeIn mt-5">
          {config.apple
              ?
              (
                  <a href={config.apple} className="bttn-image animated fadeIn " style={{ width: "200px", backgroundColor: config.mainColor }}  >
                      <img src={AppleButton} alt="" id="apple" />
                  </a>
              )
              : null}
          {config.google
              ?
              (
                  <a href={config.google} className="bttn-image  animated fadeIn" style={{ width: "200px", backgroundColor: config.mainColor }} >
                      <img src={AndroidButton} alt="" id="android" />
                  </a>
              )
              : null}
           </div>
          <button className="bttn-white mt-5" onClick={this.closeModal}>close</button>

        </Modal>
          <h2 className="pt-3 m-3">{shop.name}</h2>
          {this.showMessages(shop)}
          {this.showAppButtons()}
          {this.showOpeningTimes(shop.opening,showOpeningSection)}
          {this.showDeliveryOptions(shop)}
          {this.showCategories(shop.menu)}
        </div>
      )

}

showAppButtons () {
  return (
    <div className="m-3">
    <h5 className="mt-5">Download Our App To Order</h5>
    <div className="row justify-content-center justify-content-md-start animated fadeIn">
    
                              {config.apple
                                  ?
                                  (
                                      <a href={config.apple} className="bttn-image animated fadeIn " style={{ width: "200px", backgroundColor: config.mainColor }}  >
                                          <img src={AppleButton} alt="" id="apple" />
                                      </a>
                                  )
                                  : null}
                              {config.google
                                  ?
                                  (
                                      <a href={config.google} className="bttn-image  animated fadeIn" style={{ width: "200px", backgroundColor: config.mainColor }} >
                                          <img src={AndroidButton} alt="" id="android" />
                                      </a>
                                  )
                                  : null}
                          </div>
                          </div>
  )
}

showDeliveryOptions(shop) {

const collection = shop.features.collection;

  return (
    <div className="m-3">
      <h3 className="">Collection Options</h3>
      <div className="row">
        {Object.values(collection).map(type => {
          if (!type.enabled) return null;
          return (
            <div className="column mx-3">
              <h5 className="m-0">{`${type.name}: ${type.waitingTime}`}</h5>
            </div>
          )
        })}
      </div>
  </div>
  )
}


showOpeningTimes (opening, showOpeningSection) {
return (
  <div id="accordion">
  <h3 className="m-3">
         Our Opening Times
 </h3>
  <div className="item-box justify-content-between m-3">
   
              <div >
                <div className="row justify-content-between mx-1">
                  <div>
                  Monday
                  </div>
                  <div>
                  {opening[1].openFrom.substring(0, 5)} - {opening[1].openTo.substring(0, 5)}
                  </div>
                </div>
                <div className="row justify-content-between mx-1">
                  <div>
                  Tuesday
                  </div>
                  <div>
                  {opening[1].openFrom.substring(0, 5)} - {opening[2].openTo.substring(0, 5)}
                  </div>
                </div>
                <div className="row justify-content-between mx-1">
                  <div>
                  Wednesday
                  </div>
                  <div>
                  {opening[1].openFrom.substring(0, 5)} - {opening[3].openTo.substring(0, 5)}
                  </div>
                </div>
                <div className="row justify-content-between mx-1">
                  <div>
                  Thursday
                  </div>
                  <div>
                  {opening[1].openFrom.substring(0, 5)} - {opening[4].openTo.substring(0, 5)}
                  </div>
                </div>
                <div className="row justify-content-between mx-1">
                  <div>
                  Friday
                  </div>
                  <div>
                  {opening[1].openFrom.substring(0, 5)} - {opening[5].openTo.substring(0, 5)}
                  </div>
                </div>
                <div className="row justify-content-between mx-1">
                  <div>
                  Saturday
                  </div>
                  <div>
                  {opening[1].openFrom.substring(0, 5)} - {opening[6].openTo.substring(0, 5)}
                  </div>
                </div>
                <div className="row justify-content-between mx-1">
                  <div>
                  Sunday
                  </div>
                  <div>
                  {opening[1].openFrom.substring(0, 5)} - {opening[7].openTo.substring(0, 5)}
                  </div>
                </div>
                {/* <p class="m-0">Monday: {opening[1].openFrom.substring(0, 5)} - {opening[1].openTo.substring(0, 5)}</p>
                <p class="m-0">Tuesday: {opening[2].openFrom.substring(0, 5)} - {opening[2].openTo.substring(0, 5)}</p>
                <p class="m-0">Wednesday: {opening[3].openFrom.substring(0, 5)} - {opening[3].openTo.substring(0, 5)}</p>
                <p class="m-0">Thursday: {opening[4].openFrom.substring(0, 5)} - {opening[4].openTo.substring(0, 5)}</p>
                <p class="m-0">Friday: {opening[5].openFrom.substring(0, 5)} - {opening[5].openTo.substring(0, 5)}</p>
                <p class="m-0">Saturday: {opening[6].openFrom.substring(0, 5)} - {opening[6].openTo.substring(0, 5)}</p>
                <p class="m-0">Sunday: {opening[7].openFrom.substring(0, 5)} - {opening[7].openTo.substring(0, 5)}</p> */}
              </div>
    </div>
    </div>

   )
}

showMessages(shop) {
  const {showInAppMessage, showWelcomeMessage, welcomeMessage,inAppMessage} = shop;

  return (
    <div className="m-3">
      {showWelcomeMessage 
      ? ( <p>
          {welcomeMessage}
          </p>
        )
      : null
      }
      {showInAppMessage 
      ? ( <p>
          {inAppMessage}
          </p>
        )
      : null
      }
    </div>
  )
}

showCategories (menu) {

  //DOING JUST FIRST SHOP NEED TO ADD MULTI SHOP HANDLING

const categories = menu.categories 
const items = menu.items 

return (
  Object.keys(categories).map(i => {
    const category = categories[i];
    return (
      <div>
        <h3 className="m-3 mt-5">{category.name}</h3>
        {this.showItems(category, items)}
      </div>
    )
  })
)
}

showItems ( category, items) {
  return (
    Object.keys(items).map(i => {
      const item = items[i];

      if ( item.fkey === category.key ) {
        if (this.state.installLink === "") {
          return (
            <div 
            onClick={this.openModal}
            style={{cursor: "pointer"}}
            >
             {this.getItem(item)}
             </div>
          )
        }
        return (
          <a href={this.state.installLink}>
            {this.getItem(item)}
          </a>
        )
        

      }
      return null;
    })
  )

}

getItem(item) {
  return (
    <div 
            className="d-flex flex-row item-box m-3 justify-content-between"
          >
            <div>
              <h4 className="mb-1 flex-column"><strong>{item.name}</strong></h4>
              <p>{item.desc}</p>
              <h4>From £{item.price}</h4>
            </div>
            <div className="flex-column align-self-end" style={{width: "100px"}}>
              {this.showImage(item.image_icon, item.name)}
              {/* <div className="text-center mt-3" >BUY</div> */}
            </div>
          </div>
  )
}

showImage(image_icon, name) {
  return image_icon ? 
   <img src={image_icon}  alt={name} className="img-fluid" style={{maxWidth:"100px"}}/>
   : null
 }

}


export default Menu