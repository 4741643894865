import React, { Component } from "react"
import config from "../config";
import { Nav, Navbar, NavDropdown, Link, Container } from 'react-bootstrap';
class ShopDetails extends Component {

  render() {


    const shopsArr = Object.keys(config.shops).map(el => config.shops[el])


    return (
      <section className="section-padding" id="contact_page">
        <div className="container">
          <div className="row">
            <div class="col-xs-12 col-md-12">
              <div className="page-title text-center">

                <h5 className="title mb-5">Our Details</h5>

              </div>
            </div>
          </div>

          {this.getContactDetails(shopsArr)}

        </div>

      </section>
    );

  }


  getContactDetails(shops) {

    console.log("shops", shops)


    return (
      shops.map(i => {
        return (
            <div  className="d-flex mb-5 justify-content-center">
              <div  className="text-center">
                      <div className=" "><img  className="shadow rounded" src={(i.image_url)} alt="" id="hero" /></div>
                      <h4 className=" mb-3
                       mt-4">{i.name}</h4>
                      <h5 className="lead mb-2">Opening Times</h5>
                      <div className="mb-3">
                      <p class="m-0" style={{lineHeight:'1.4em'}}>Monday: {i.opening[1].closed ? "Closed" : `${i.opening[1].openFrom.substring(0, 5)} - ${i.opening[1].openTo.substring(0, 5)}`}</p>
                      <p class="m-0" style={{lineHeight:'1.4em'}}>Tuesday:  {i.opening[2].closed ? "Closed" : `${i.opening[2].openFrom.substring(0, 5)} - ${i.opening[2].openTo.substring(0, 5)}`}</p>
                      <p class="m-0" style={{lineHeight:'1.4em'}}>Wednesday:  {i.opening[3].closed ? "Closed" : `${i.opening[3].openFrom.substring(0, 5)} - ${i.opening[3].openTo.substring(0, 5)}`}</p>
                      <p class="m-0" style={{lineHeight:'1.4em'}}>Thursday:  {i.opening[4].closed ? "Closed" : `${i.opening[4].openFrom.substring(0, 5)} - ${i.opening[4].openTo.substring(0, 5)}`}</p>
                      <p class="m-0" style={{lineHeight:'1.4em'}}>Friday:  {i.opening[5].closed ? "Closed" : `${i.opening[5].openFrom.substring(0, 5)} - ${i.opening[5].openTo.substring(0, 5)}`}</p>
                      <p class="m-0" style={{lineHeight:'1.4em'}}>Saturday:  {i.opening[6].closed ? "Closed" : `${i.opening[6].openFrom.substring(0, 5)} - ${i.opening[6].openTo.substring(0, 5)}`}</p>
                      <p class="m-0" style={{lineHeight:'1.4em'}}>Sunday:  {i.opening[7].closed ? "Closed" : `${i.opening[7].openFrom.substring(0, 5)} - ${i.opening[7].openTo.substring(0, 5)}`}</p>
                      </div>
                      { !i.appMessages || !i.appMessages.addressLine1 ? null : (
                        <div>
                        <h5 className="lead mb-0 ">Address</h5>
                        <p className="">{i.appMessages.addressLine1}, {i.appMessages.addressLine2}, {i.appMessages.postcode}, {i.appMessages.townCity}</p>
                        </div>
                      )
                      }

                      {!i.appMessages || !i.appMessages.telephone ? null : (
                        <div>
                          <h5 className="lead mb-0">Telephone</h5>
                          <p>Tel: {i.appMessages.telephone}</p>
                        </div>
                      )
                      }

                      { !i.email ? null :
                      (
                        <div>
                         <h5 className="lead mb-0">Email</h5>
                        <p>{i.email}</p>
                        </div>
                      )
                      }

                    {/* <Nav.Link key={i.key} href={`/menu/${i.key}`} className="dropdown-item"> 
                        {i.name}   
                    </Nav.Link> */}

              </div>
          </div>
        )}
      ))

}


}

  export default ShopDetails