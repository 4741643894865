import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import config from "../config";
//import Marker from "../images/map-marker.png"
import Marker from "../images/marker.svg"

const AnyReactComponent = ({ shopName, isDup }) => {

  const margin = isDup > 0 ? "mt-3" : null

  return (
    <div>

      <img  src={config.logo} width={40} alt="map marker"  />



    </div>
  )
}


class Map extends Component {


  render() {

    const shopsArr = Object.keys(config.shops).map(el => config.shops[el])

    //Set the center of the map to the co-ords of the first shop
    const defaultCenter = {
      lat: parseFloat(shopsArr[0].lat),
      lng: parseFloat(shopsArr[0].long)
    }

    const zoom = parseInt(config.colours.zoom)


    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '80vh', width: '100%' }} id="map_page">
        <GoogleMapReact
          bootstrapURLKeys={{ key: config.googleKey }}
          defaultCenter={defaultCenter}
          defaultZoom={zoom}
        >
          {this.placeMarkers(shopsArr)}

        </GoogleMapReact>
      </div>
    );
  }

  placeMarkers(shops) {

    return (
      shops.map((el, i, arr) => {

        const longlat = el.lat + el.long

        //Returns 1 if long lat is only in the array onces, 2 of its found more than once. Used to seperate the map names for shops in the same place
        const isDup = arr.map((el1, i1) => {

          //Check an element with every other element other than itself and the ones before it to see if it's a dup (WARNING the 2nd DUP needs dealing with)
          if (i !== i1 && i <= i1) {
            // console.log("IN ARRAY",i,i1, longlat, (el1.lat + el1.long))
            if (longlat === (el1.lat + el1.long)) return 1
            return 0
          }
          return 0

        }).reduce((a, b) => a + b, 0)

        // console.log("ISDUP",isDup, el)

        return (
          <AnyReactComponent lat={parseFloat(el.lat)} lng={parseFloat(el.long)} shopName={el.name} isDup={isDup} />
        )
      })
    )
  }


}

export default Map;