
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/style.css';
import './css/animate.css';
import './css/icomoon.css';
//import './css/normalize.css';
import './css/responsive.css';


import Header from './components/header.js'
import About from './components/about.js'
import Map from './components/map'
import Menu from './components/menu'

import config from './config';
import Hero from './images/header-bg.jpg';
import ShopDetails from './components/shopDetails';

class App extends React.Component {

  constructor() {
    super();
    this.state = {
      menu: {},
      loaded: false,
      currentShopKey: 0,
      colours: {}
    };


  }

  componentDidMount() {

    document.title = config.clientName + " - Order Online"


  }

  render() {


    return (
      <div>
        <Header />
        <About />
        <ShopDetails />
        <Map />

      </div>
    );
  }


  handleSelectShop = (key) => {
    this.setState({ currentShopKey: key })
  }


}

export default App

