

import React, { Component, Fragment, StyleSheet } from "react"
import { Nav, Navbar, NavDropdown, Link, Container } from 'react-bootstrap';
import config from "../config";


class NavBar extends Component {
  render() {

    return (
      <Navbar
        id="site-navbar"
        className="nav-shadow"
        expand="md"
        style={{backgroundColor: config.colours.mainColour}}
        fixed="top"
        varient="dark"
      >
     <Container>
          <Navbar.Brand href="/"  style={{color: config.colours.fontColour}}>
            <img
              src={config.logo}
              width="30"
              height="30"
              className="d-inline-block align-top mr-3"
              alt={config.clientName}


            />{config.clientName}

          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/#home_page" style={{color: config.colours.fontColour}}>Home</Nav.Link>
              <Nav.Link href="/#about_page" style={{color: config.colours.fontColour}}>About</Nav.Link>
              <Nav.Link href="/#map_page" style={{color: config.colours.fontColour}}>Location</Nav.Link>
              <Nav.Link href="/#contact_page" style={{color: config.colours.fontColour}}>Details</Nav.Link>
                {this.getShops()}
            </Nav>
          </Navbar.Collapse>
          </Container>
      </Navbar>





    );
  }


  getShops = () => {
    const shops = config.shops 
    if (!config.showMenu || config.showMenu === "false" || config.showMenu === false) return null
    //Create Array Sorted By Ordinal
    const shopsArr = Object.keys(shops).map(i => { return shops[i] }).sort((a, b) => a.ordinal - b.ordinal)

    if (shopsArr.length === 1) {
      return (
        <Nav.Link key={shopsArr[0].key} href={`/menu/${shopsArr[0].key}`} className="dropdown-item" style={{color: config.colours.fontColour}}> 
           Menu
        </Nav.Link>
      )

    }

    //console.log("Current Shop",this.state.currentShop)
    return (
      <NavDropdown 
        title={<div style={{display: "inline-block", color: config.colours.fontColour}}>Menu</div>} 
        id="basic-nav-dropdown"
      >
          {shopsArr.map((el, i) => { 
            return (
                    <Nav.Link 
                      key={el.key} href={`/menu/${el.key}`} 
                      className="dropdown-item" 
                      style={{color: config.colours.fontColour}}
                    > 
                        {el.name}   
                    </Nav.Link>
          )})
          }       
      </NavDropdown>
     
    )
  }


}

export default NavBar