
import React, { Component } from "react"
import config from "../config";


class About extends Component {
  render() {


    return (

      <section className="section-padding mt-5" id="about_page" style={{ backgroundColor: config.colours.secondColour + '10' }}>
        <div className="container">

          <div className="col-xs-12 col-md-12">
            <div className="page-title text-center d-flex flex-column">
              <img className="zoom mb-5" src={config.logo} alt="About Logo" style={{ width: "100px", borderRadius: "10px" }} />

              <h5 className="title mb-5">{config.aboutTitle}</h5>

              <h3 className="">{config.aboutSubTitle}</h3>


              <p className=" mt-3" >{config.aboutText}</p>

            </div>
          </div>

        </div>
      </section>
    );
  }
}

export default About